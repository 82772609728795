export default {
  commission: {
    commission_list: "佣金列表",
    time_of_occurrence: "发生时间",
    status: "状态",
    update_time: "更新时间",
    commission_rate: "佣金比例",
    business_type: "业务类型",
    calculation_method: "计算方式",
    related_business: "相关业务",
    unconfirmed: "未确认",
    confirmed: "已确认",
    cancelled: "已取消",
  }
}