export default {
  "links": {
    "recommended_link_use_cases": "Example of promotion link using.",
    "create_label": "Create Tag",
    "register_page": "Register Page",
    "label_management": "Tag Management",
    "label_name": "Tag Name",
    "tag_value": "Tag Value",
    "creation_time": "Creating Time",
    "visits": "Visitor Volume",
    "number_of_customer_registrations": "Client's registration quantity",
    "commission_to_be_confirmed": "Commission to be confirmed",
    "commission_confirmed": "Confirmed commission",
    "commission_cancelled": "Cancelled commission",
    "total": "Total",
    "link": "Link",
    "recommended_link": "Promotion Link",
    "page": "Page",
    "custom": "User define"
  }
}