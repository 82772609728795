<template>
  <!-- 用于 路由组件 的统一样式 -->
  <div class="content-container">
    <div class="content-title"
         v-if="hasTitle">
      <h3>
        <slot name="contentTitle"></slot> 
      </h3>
    </div>

    <!-- 没有底部边框的title -->
    <div class="content-title-no-button-border"
         v-if="hasTitleNoBorder">
      <h3>
        <slot name="contentTitleNoBorder"></slot>
      </h3>
    </div>

    <div :class="['content-body',{'no-footer':!hasFooter},{'no-title':!hasTitle}]">
      <div class="body-wrap"
           ref="contentBodyWrap">
        <slot name="contentBody"
              :contentHeight="contentHeight"></slot>
      </div>
    </div>
    <div class="content-footer"
         v-if="hasFooter">
      <slot name="contentFooter"></slot>
    </div>
  </div>

</template>

<script>
import { useSlots, ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
export default ({
  name: "Content",
  setup () {
    const hasFooter = ref(!!useSlots().contentFooter);
    const hasTitle = ref(!!useSlots().contentTitle);
    const hasTitleNoBorder = ref(!!useSlots().contentTitleNoBorder);
    const contentBodyWrap = ref(null);
    const contentHeight = ref(500);
    // const contentHeight = computed(()=>{
    //    if(contentBodyWrap.value && contentBodyWrap.value.offsetHeight){
    //       return contentBodyWrap.value.offsetHeight;
    //    }
    //    return 500;
    // });
    let debounceTimer = null;
    const calcHeight = () => {
      if (contentBodyWrap.value && contentBodyWrap.value.offsetHeight) {
        contentHeight.value = contentBodyWrap.value.offsetHeight;
      }
    }

    const debounceCalc = () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      debounceTimer = setTimeout(calcHeight, 200);
    }

    onMounted(() => {
      calcHeight();
      window.addEventListener("resize", debounceCalc);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", debounceCalc)
    })
    return {
      hasFooter,
      hasTitle,
      contentBodyWrap,
      contentHeight,
      calcHeight,
      hasTitleNoBorder
    };
  }
})
</script>

<style lang="less" scoped>
.content-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.content-title-no-button-border {
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  background: #fff;
  min-height: 40px;
}

.content-title {
  // position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  background: #fff;
  min-height: 40px;
  border-bottom: 1px solid #f0f0f0;
}
.content-body {
  min-height: 300px;
  width: 100%;
  height: 100%;
  padding: 16px 0px 16px;
  flex-grow: 1;
  box-sizing: border-box;
  overflow: hidden;
}
.content-body .body-wrap {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-footer {
  // position: absolute;
  // bottom: 0px;
  // left: 0px;
  width: 100%;
  // z-index: 1;
  // background: #fff;
  min-height: 40px;
}
.content-body.no-footer {
  padding-bottom: 0;
}
.content-body.no-title {
  padding-top: 0;
}
</style>