import client from "@/api/client";

const Api = {
  // 获取合作伙伴佣金账户信息
  getPartnerBalance: "/api/SupplierService/PartnerBalance/Get",
  // 获取合作伙伴佣金记录列表
  getListPaged: "/api/SupplierService/PartnerCommissionRecord/GetListPaged",
}

export const getPartnerBalance = () => {
  return client.request({
    url: Api.getPartnerBalance,
    method: "GET",
  });
};

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: "POST",
  });
};