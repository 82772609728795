<template>
  <a-modal
    v-model:visible="visible"
    :title="`${$t('menu.links')}-${$t('common.create')}`"
    :confirm-loading="confirmLoading"
    @ok="handleSubmit"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
    >
      <a-form-item :label="$t('links.label_name')" name="name">
        <a-input
          v-model:value="formState.name"
          :placeholder="$t('links.label_name')"
          allowClear
        />
      </a-form-item>
      <a-form-item :label="$t('links.tag_value')" name="value">
        <a-input
          v-model:value="formState.value"
          :placeholder="$t('links.tag_value')"
          allowClear
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, nextTick } from "vue";
import { Modal, Form, Input, message } from 'ant-design-vue';
import { createPartnerLabel } from "@/api/modules/links";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);

    const state = reactive({
      visible: false,
      confirmLoading: false,
      formState: {
        name: null,
        value: null,
      },
    });

    const rules = {
      name: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("links.label_name")]),
      },
      value: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("links.tag_value")]),
      },
    };

    const handleSubmit = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = JSON.parse(JSON.stringify(state.formState));
        await createPartnerLabel(data);
        message.success(vueI18n.t("common.succeed"));
        state.visible = false;
        emit("refresh");
      } catch (error) {
        console.log(error);
      } finally {
        state.confirmLoading = false;
      }
    };

    const init = () => {
      state.visible = true;
      state.formState.id = null;
      nextTick(() => {
        formRef.value.resetFields();
      });
    };

    return {
      ...toRefs(state),
      formRef,
      rules,
      labelCol: { style: { width: "120px" } },
      handleSubmit,
      init,
    };
  },
});
</script>
