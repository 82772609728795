export const getDynamicRoutes = ()=>{
  return [
    {
      "id": 1,
      "parent": 0,
      "path": "/",
      "name": "dashboard",
      "type": "group",
      "icon": "DashboardOutlined",
      "title": "Dashboard",
      "sort": 0,
      "link": "",
      "hidden": false,
      "text": "menu.home"
    },
    {
      "id": 101,
      "parent": 1,
      "path": "/dashboard",
      "name": "dashboard_index",
      "type": "router",
      "icon": "DashboardOutlined",
      "title": "Dashboard",
      "sort": 0,
      "link": "",
      "hidden": false,
      "text": "menu.dashboard"
    },
    {
      "id": 8,
      "parent": 0,
      "path": "/account",
      "name": "account",
      "type": "group",
      "icon": "UserOutlined",
      "title": "Account",
      "sort": 0,
      "link": "",
      "hidden": true,
      "text": "menu.account"
    },
    {
      "id": 800,
      "parent": 8,
      "path": "/account/info",
      "name": "account_info",
      "type": "router",
      "icon": "",
      "title": "",
      "sort": 0,
      "link": "",
      "hidden": true,
      "text": "menu.account_info"
    },
    {
      "id": 20,
      "parent": 0,
      "path": "/links",
      "name": "links",
      "type": "router",
      "icon": "ShareAltOutlined",
      "title": "links of referral",
      "sort": 0,
      "link": "",
      "hidden": false,
      "text": "menu.links"
    },
    {
      "id": 30,
      "parent": 0,
      "path": "/customer",
      "name": "customer",
      "type": "router",
      "icon": "UsergroupDeleteOutlined",
      "title": "Customer Management",
      "sort": 0,
      "link": "",
      "hidden": false,
      "text": "menu.customer"
    },
    {
      "id": 40,
      "parent": 0,
      "path": "/commission",
      "name": "commission",
      "type": "router",
      "icon": "WalletOutlined",
      "title": "Commission Management",
      "sort": 0,
      "link": "",
      "hidden": false,
      "text": "menu.commission"
    },
    {
      "id": 50,
      "parent": 0,
      "path": "/settlement",
      "name": "settlement",
      "type": "router",
      "icon": "PropertySafetyOutlined",
      "title": "settlement",
      "sort": 0,
      "link": "",
      "hidden": false,
      "text": "menu.settlement"
    },
  ]
}