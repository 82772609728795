export default {
  dashboard: {
    dear_partner: "尊敬的合作伙伴",
    introduce_customers_today: "今日引荐客户",
    commission_today: "今日佣金",
    commission: "佣金",
    total_recommended_customers: "总引荐客户数",
    total_commission: "佣金总额",
    this_month: "本月",
    this_week: "本周",
    yesterday: "昨日",
  }
}