export default {
  "commission": {
    "commission_list": "Commission List",
    "time_of_occurrence": "Happening Time",
    "status": "Status",
    "update_time": "Update Time",
    "commission_rate": "Commission Ratio",
    "business_type": "Business Type",
    "calculation_method": "Calculating Method",
    "related_business": "Relevant Business",
    "unconfirmed": "Unconfirmed",
    "confirmed": "Confirmed",
    "cancelled": "Cancelled"
  }
}