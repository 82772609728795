export default {
  "enum": {
    "empty": "",
    "commission_record_status_1": "To be confirmed",
    "commission_record_status_2": "Confirmed",
    "commission_record_status_3": "Cancelled",
    "commission_business_type_1": "Storage Service",
    "commission_business_type_2": "Shipping Service",
    "compute_mode_type_1": "Statistics by order",
    "compute_mode_type_2": "Statistics by month",
    "related_business_type_1": "UPSDSASD",
    "related_business_type_2": "Sum-calculation by month",
    "settlement_channel_type_1": "Wire Transfer"
  }
}