
import Layout from "@/views/layout/Index.vue";
export default {
    Layout,

    "dashboard": () => import("@/views/dashboard/Index.vue"),
    "dashboard_index": () => import("@/views/dashboard/Index.vue"),

    "account_info": () => import("@/views/accounts/Info.vue"),

    "links": () => import("@/views/links/Index.vue"),
    "customer": () => import("@/views/customer/Index.vue"),
    "commission": () => import("@/views/commission/Index.vue"),
    "settlement": () => import("@/views/settlement/Index.vue"),
}