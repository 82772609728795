export default {
  links: {
    recommended_link_use_cases: "推荐链接用例",
    create_label: "创建标签",
    register_page: "注册页面",
    label_management: "标签管理",
    label_name: "标签名称",
    tag_value: "标签值",
    creation_time: "创建时间",
    visits: "访问量",
    number_of_customer_registrations: "客户注册数",
    commission_to_be_confirmed: "待确认佣金",
    commission_confirmed: "已确认佣金",
    commission_cancelled: "佣金已取消",
    total: "总额",
    link: "链接",
    recommended_link: "推广链接",
    page: "页面",
    custom: "自定义",
  }
}