<template>

  <div id="content">
    <router-view v-slot="{ Component }">
      <transition :name="tranName">
        <keep-alive :include="keepAliveIncludes">
          <component :is="Component"
                     :key="$route.path"></component>
        </keep-alive>
      </transition>
    </router-view>
    <!-- <router-view v-slot="{ Component }" v-if="routerActive">
            <transition :name="tranName">
                <keep-alive v-if="keepAlive">
                    <component :is="Component"></component>
                </keep-alive>
                <component v-else :is="Component"></component>
            </transition>
        </router-view> -->
    <!-- <div class="content-loading"
         v-else>
      <a-spin size="large" />
    </div> -->
  </div>
</template>

<script>
import { computed, ref } from "vue"
import { useStore } from "vuex"
import { useRoute } from "vue-router";
import { Spin } from 'ant-design-vue';
import { useTab } from '../../../../hooks/tabs/index';
export default {
  name: "Content",
  components: {
    ASpin: Spin
  },
  setup () {
    const { visitedRoutes } = useTab();
    let route = useRoute();
    const tranName = ref("fade");
    const { getters, commit, dispatch } = useStore();
    const routerActive = computed(() => getters.routerActive);
    const keepAliveIncludes = computed(() => visitedRoutes.value.map(item=>item.name));

    return {
      routerActive,
      tranName,
      keepAliveIncludes
    }
  }
}
</script>

<style lang="less" scoped>
#content {
  height: 100%;
}
.content-loading {
  text-align: center;
  line-height: 200px;
}
</style>