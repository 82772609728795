export default {
  "dashboard": {
    "dear_partner": "Dear Partner",
    "introduce_customers_today": "Promoted Clients Today",
    "commission_today": "Today Commission",
    "commission": "Commission",
    "total_recommended_customers": "Total promoted clients quantity",
    "total_commission": "Total commission amount",
    "this_month": "This month",
    "this_week": "This week",
    "yesterday": "Yesterday"
  }
}