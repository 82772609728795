export default {
  account: {
    essential_information: "基本信息",
    user_name: "用户名称",
    timezone: "时区",
    mobile_phone: "手机",
    last_login_time: "上次登陆时间",
    last_login_ip: "上次登陆IP",
    secure_mailbox: "安全邮箱",
    account_security: "账户安全",
    change_password: "修改密码",
    original_password: "原密码",
    new_password: "新密码",
    enter_the_new_password_again: "二次输入新密码",
    please_input: "请输入",
    the_passwords_entered_twice_do_not_match: "两次输入的密码不匹配",
    password_modified_successfully: "密码修改成功",
    amend: "修改",
    add: "添加",
    company_list: "公司列表",
    account_list: "账户列表",

    all: "全部",
    check_pending: "待审核",
    in_the_activity: "活动中",
    audit_failed: "审核未通过",
    pass_the_audit: "审核通过",

    company_name: "公司名称",
    registr_id: "注册号",

    the_head_of_a_company: "公司负责人",

    country: "所在国家",
    registration_date: "注册时间",
    approval_time: "审核通过时间",
    last_login_int: "最后登录时间",
    account_status: "账户状态",
    audit_status: "审核状态",
    business_manager: "业务负责人",
    service: "客服",
    operation: "操作",
    operator: "操作人",
    details: "详情",
    company_details: "公司详情",

    audit: "审核",
    audit_result: "审核结果",

    freeze: "冻结",
    unfreeze: "解冻",

    account_audit: "账户审核",
    pass: "通过",
    reject: "拒绝",
    business_license:"营业执照",
    account_principal: "账户负责人",
    phone: "联系电话",
    audit_result_placeholder:'审核未通过的原因或审核通过的备注',
    account_balance:"账户余额",
    overdraft_limit:"透支限额",
    rxpire_date:"到期时间",
    
    overdraft_status:"透支状态",
    serial_number:"序号",
    log_in_time:"登录时间",
    time:"时间",
    quota_adjustment_record:"额度调整记录",
    amount:"金额",
    adjustment_type:"调整类型",
    login_id:"登录账号",

    account_name:"账户名",
    locked:"已锁定",
    locking:"锁定",
    unlock:"解锁",

    login_account:"登录账号",
    you_are_performing_a_delete_operation: "您正在执行删除操作",

    update_timezone: "修改时区",
    choose_timezone: "选择时区"
    
  },
};
