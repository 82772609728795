<template>
  <a-modal
    v-model:visible="visible"
    width="700px"
    :title="$t('links.recommended_link')"
    :footer="null"
    @ok="handleClose"
  >
    <a-form>
      <a-form-item :label="$t('links.page')">
        <a-select v-model:value="selectValue" style="width: 200px;" @change="handleSelectChange">
          <a-select-option
            v-for="(item, index) in selecctList"
            :key="index"
            :value="item.value"
            >{{ item.title }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('links.link')">
        <a-row align="middle">
          <a-col>{{ domainHost }}/</a-col>
          <a-col>
            <a-input v-model:value="inputValue" style="width: 100px;" allowClear :readonly="selectValue !== 'custom'" />
          </a-col>
          <a-col>?pt={{ partnerNo }}&tag={{ tagValue }}</a-col>
          <a-col>
            <a-button
              type="link"
              size="small"
              @click="handleOpenCopyModal"
            >
              <template #icon>
                <CopyOutlined />
              </template>
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, nextTick } from "vue";
import { Modal, Input, Select, Form, Row, Col, Button, message } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import useClipboard from "vue-clipboard3";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
    ARow: Row,
    ACol: Col,
    AButton: Button,
  },
  props: {
    domainHost: String,
    partnerNo: String,
  },
  setup(props) {
    const vueI18n = useI18n({ useScope: "global" });
    const { toClipboard } = useClipboard();

    const state = reactive({
      visible: false,
      selecctList: [
        {
          title: vueI18n.t("menu.home"),
          value: "home",
        },
        {
          title: vueI18n.t("links.register_page"),
          value: "register",
        },
        {
          title: vueI18n.t("links.custom"),
          value: "custom",
        },
      ],
      selectValue: null,
      inputValue: null,
      tagValue: "",
    });

    const handleSelectChange = (e) => {
      if (e === 'custom') {
        state.inputValue = null
      } else {
        state.inputValue = e
      }
    };

    const handleClose = () => {
      state.visible = false
    };

    const handleOpenCopyModal = async () => {
      if (!state.inputValue) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('links.custom') + vueI18n.t('links.page')]))
        return
      }
      try {
        let link = `${props.domainHost}/${state.inputValue}?pt=${props.partnerNo}&tag=${state.tagValue}`
        await toClipboard(link);
        message.success(vueI18n.t("common.copy") + vueI18n.t("common.succeed"));
      } catch (error) {}
    };

    const init = (tag) => {
      state.tagValue = tag;
      state.visible = true;
      nextTick(() => {
        state.selectValue = state.selecctList[0].value
        state.inputValue = state.selecctList[0].value
      });
    };

    return {
      ...toRefs(state),
      handleSelectChange,
      handleOpenCopyModal,
      handleClose,
      init,
    };
  },
});
</script>
