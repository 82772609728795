<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.customer") }}
      <a-button
        type="ghost"
        shape="circle"
        size="small"
        @click="handleInitSearch"
        :loading="tableData.loading"
      >
        <template #icon>
          <RedoOutlined />
        </template>
      </a-button>
    </template>
    <template #contentBody="wrap">
      <a-table
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 50 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #shopName="{ record }">
          <span>{{ record.shopName || '-' }}</span>
        </template>
        <template #shopRegisterTime="{ record }">
          <span>{{ $filters.utcToCurrentTime(record.shopRegisterTime) }}</span>
        </template>
        <template #shopContryName="{ record }">
          <span>{{ getLanguageName(record.shopContryCnName, record.shopContryEnName) }}</span>
        </template>
        <template #partnerLabelName="{ record }">
          <span v-if="record.partnerLabelName">{{ record.partnerLabelName }}</span>
          <span v-else>-</span>
        </template>
        <template #isActive="{ record }">
          <span v-if="record.isActive">{{ $t('customer.normal_commission_distribution') }}</span>
          <span v-else>{{ $t('customer.termination_of_commission_distribution') }}</span>
        </template>
        <template #awaitConfirmAmount="{ record }">
          <span>{{ record.currencyCode }} {{ record.currencySymbol }}{{ roundNumber(record.awaitConfirmAmount) }}</span>
        </template>
        <template #confirmAmount="{ record }">
          <span>{{ record.currencyCode }} {{ record.currencySymbol }}{{ roundNumber(record.confirmAmount) }}</span>
        </template>
        <template #canceledAmount="{ record }">
          <span>{{ record.currencyCode }} {{ record.currencySymbol }}{{ roundNumber(record.canceledAmount) }}</span>
        </template>
        <template #amount="{ record }">
          <span>{{ record.currencyCode }} {{ record.currencySymbol }}{{ roundNumber(record.amount) }}</span>
        </template>
      </a-table>
    </template>
    <template #contentFooter
      ><CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager
    ></template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Card, Row, Col, Button, Space, Table } from "ant-design-vue";
import Content from "@/views/components/Content";
import CPager from "@/views/components/CPager";
import { useI18n } from "vue-i18n/index";
import { getListPaged } from "@/api/modules/customer";
import { roundNumber } from '@/utils/general';

export default defineComponent({
  name: "customer",
  components: {
    ACard: Card,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ASpace: Space,
    ATable: Table,
    Content,
    CPager,
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        title: () => vueI18n.t("customer.customer"),
        width: 150,
        fixed: "right",
        slots: {
          customRender: "shopName",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("account.registration_date"),
        width: 120,
        slots: {
          customRender: "shopRegisterTime",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("customer.country_of_registration"),
        width: 150,
        slots: {
          customRender: "shopContryName",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("customer.source_label"),
        width: 120,
        slots: {
          customRender: "partnerLabelName",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("customer.available_status"),
        width: 100,
        slots: {
          customRender: "isActive",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("customer.commission_to_be_confirmed"),
        width: 120,
        slots: {
          customRender: "awaitConfirmAmount",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("links.commission_confirmed"),
        width: 120,
        slots: {
          customRender: "confirmAmount",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("links.commission_cancelled"),
        width: 120,
        slots: {
          customRender: "canceledAmount",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("links.total"),
        width: 120,
        slots: {
          customRender: "amount",
        },
        align: "center",
      },
    ];

    // const hiddenText = (text = '') => {
    //   if (!text) {
    //     return '-'
    //   }
    //   return text.substr(0, 3) + '*****' + text.substr(-2)
    // }

    const getLanguageName = (cnName, enName) => {
      if (!cnName || !enName) {
        return '-'
      }
      if (getters.language === 'zh-CN') {
        return cnName
      } else {
        return enName
      }
    }

    const getPageData = () => {
      state.tableData.loading = true
      const data = Object.assign({}, state.tableData.pageData)
      getListPaged(data).then(({ result }) => {
        if (result) {
          let { totalCount = 0, items = [] } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        }
      }).finally(() => {
        state.tableData.loading = false
      })
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    onMounted(handleInitSearch);

    return {
      ...toRefs(state),
      columns,
      handlePage,
      handleInitSearch,
      // hiddenText,
      getLanguageName,
      roundNumber,
    };
  },
});
</script>
