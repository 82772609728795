export default {
  customer: {
    customer: "客户",
    country_of_registration: "注册国家",
    source_label: "来源标签",
    available_status: "可用状态",
    commission_to_be_confirmed: "待确认佣金",
    normal_commission_distribution: "正常分佣",
    termination_of_commission_distribution: "终止分佣",
  }
}