import client from "@/api/client";

const Api = {
  // 佣金统计
  getCommissionStatistics: "/api/SupplierService/PartnerBalance/GetCommissionStatistics",
  // 引荐客户统计
  getCustomerStatistics: "/api/SupplierService/PartnerBalance/GetCustomerStatistics",
}

export const getCommissionStatistics = () => {
  return client.request({
    url: Api.getCommissionStatistics,
    method: "GET",
  });
};

export const getCustomerStatistics = () => {
  return client.request({
    url: Api.getCustomerStatistics,
    method: "GET",
  });
};