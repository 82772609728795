import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//  import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import * as i18nLocal from "./locale/index.js";
import "./styles/main.less";
import "./mock/index";
import * as antIcons from "@ant-design/icons-vue";
import { getEnumLangkey } from "./utils/general";
import filters from "./filters";
import {compatibleInit} from "./compatible"

/** 处理兼容性脚本 */
compatibleInit();

const app = createApp(App);
app.use(i18nLocal.default);
app.use(store);
app.use(router);
app.mount("#app");

/**引入全部Ant Design Icon */
Object.keys(antIcons).forEach((key) => {
  app.component(key, antIcons[key]);
});

app.config.globalProperties.$antIcons = antIcons;

app.config.globalProperties.$enumLangkey = getEnumLangkey;
app.config.globalProperties.$filters = filters;
