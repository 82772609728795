import client from "@/api/client";

const Api = {
  // 获取合作伙伴租户关系分页列表
  getListPaged: "/api/SupplierService/PartnerShopRl/GetListPaged",
}

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: "POST",
  });
};