
<template>
  <Content>
    <template #contentBody>
      <p>{{ $t('dashboard.dear_partner') }}: {{ partner.partnerName }}</p>
      <p>ID: {{ partner.partnerNo }}</p>
      <a-spin :spinning="loading">
        <a-card class="mt-4">
          <a-row>
            <a-col :span="8" class="content-card">
              <p>{{ $t('dashboard.introduce_customers_today') }}</p>
              <h1>{{ toDayCountCustomer }}</h1>
            </a-col>
            <a-col :span="8" class="content-card">
              <p>{{ $t('dashboard.commission_today') }}</p>
              <h1>{{ toDayCountCommission }}</h1>
            </a-col>
          </a-row>
        </a-card>
        <div class="mt-4 mb-2">{{ $t('customer.customer') }}</div>
        <a-row :gutter="[32, 32]">
          <a-col v-for="(item, index) in customerList" :key="index" :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
            <a-card class="content-card">
              <p>{{ $t(item.name) }}</p>
              <h1>{{ item.value }}</h1>
            </a-card>
          </a-col>
        </a-row>
        <div class="mt-3 mb-2">{{ $t('dashboard.commission') }}</div>
        <a-row :gutter="[32, 32]">
          <a-col v-for="(item, index) in commissionList" :key="index" :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
            <a-card class="content-card">
              <p>{{ $t(item.name) }}</p>
              <h1>{{ item.value }}</h1>
            </a-card>
          </a-col>
        </a-row>
      </a-spin>
    </template>
  </Content>
</template>
<script>
import { defineComponent, onActivated, reactive, toRefs } from 'vue'
import { Card, Row, Col, Spin } from 'ant-design-vue';
import Content from '@/views/components/Content';
import { useI18n } from "vue-i18n/index";
import { getCommissionStatistics, getCustomerStatistics } from '@/api/modules/dashboard';
import { useStore } from "vuex";
import { roundNumber } from '@/utils/general';

export default defineComponent({
  name: "dashboard_index",
  components: {
    ACard: Card,
    ARow: Row,
    ACol: Col,
    ASpin: Spin,
    Content,
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      partner: {
        partnerName: '',
        partnerNo: '',
      },
      loading: true,
      toDayCountCustomer: 0,
      toDayCountCommission: 0,
      customerList: [
        {
          name: 'dashboard.total_recommended_customers',
          key: 'total',
          value: 0
        },
        {
          name: 'dashboard.this_month',
          key: 'monthCount',
          value: 0
        },
        {
          name: 'dashboard.this_week',
          key: 'weekCount',
          value: 0
        },
        {
          name: 'dashboard.yesterday',
          key: 'yesterdayCount',
          value: 0
        },
      ],
      commissionList: [
        {
          name: 'dashboard.total_commission',
          key: 'amount',
          value: 0
        },
        {
          name: 'dashboard.this_month',
          key: 'monthAmount',
          value: 0
        },
        {
          name: 'dashboard.this_week',
          key: 'weekAmount',
          value: 0
        },
        {
          name: 'dashboard.yesterday',
          key: 'yesterdayAmount',
          value: 0
        },
      ],
    })

    const getData = async () => {
      try {
        state.loading = true
        let { result: customerStatistics } = await getCustomerStatistics()
        state.customerList = state.customerList.map(item => {
          if (Object.hasOwnProperty.call(customerStatistics, item.key)) {
            item.value = customerStatistics[item.key]
          }
          return item
        })
        state.toDayCountCustomer = customerStatistics.toDayCount

        let { result: commissionStatistics } = await getCommissionStatistics()
        state.commissionList = state.commissionList.map(item => {
          if (Object.hasOwnProperty.call(commissionStatistics, item.key)) {
            item.value = commissionStatistics.currencySymbol + roundNumber(commissionStatistics[item.key])
          }
          return item
        })
        state.toDayCountCommission = commissionStatistics.currencySymbol + roundNumber(commissionStatistics.toDayAmount)
      } catch (error) {
        
      } finally {
        state.loading = false
      }
    }

    const init = () => {
      let userInfo = getters.userInfo
      if (userInfo) {
        for (const key in state.partner) {
          if (Object.hasOwnProperty.call(userInfo, key)) {
            state.partner[key] = userInfo[key]
          }
        }
      }
    }

    onActivated(() => {
      init()
      getData()
    })

    return {
      ...toRefs(state),
    }
  },
})
</script>
<style lang="less" scoped>
.content-card {
  text-align: center;
  padding: 16px 0;
}
.content-card p {
  margin-bottom: 10px;
  font-weight: bold;
}
.content-card h1 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: bold;
}
</style>