<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.commission") }}
      <a-button
        type="ghost"
        shape="circle"
        size="small"
        @click="handleInitSearch"
        :loading="tableData.loading"
      >
        <template #icon>
          <RedoOutlined />
        </template>
      </a-button>
    </template>
    <template #contentBody="wrap">
      <a-row :gutter="80">
        <a-col>
          <h3 style="font-weight: bold;">{{ $t('links.total') }}</h3>
          <p>{{ balanceData.amount }}</p>
        </a-col>
        <a-col>
          <h3 style="font-weight: bold;">{{ $t('commission.unconfirmed') }}</h3>
          <p>{{ balanceData.awaitConfirmAmount }}</p>
        </a-col>
        <a-col>
          <h3 style="font-weight: bold;">{{ $t('commission.confirmed') }}</h3>
          <p>{{ balanceData.confirmAmount }}</p>
        </a-col>
        <a-col>
          <h3 style="font-weight: bold;">{{ $t('commission.cancelled') }}</h3>
          <p>{{ balanceData.canceledAmount }}</p>
        </a-col>
      </a-row>
      <div class="mt-3 mb-2">{{ $t("commission.commission_list") }}</div>
      <a-table
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 170 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #creationTime="{ record }">
          <span>{{ $filters.utcToCurrentTime(record.creationTime) }}</span>
        </template>
        <template #commissionRecordStatus="{ record }">
          <span>{{ $t($enumLangkey('commissionRecordStatus', record.commissionRecordStatus)) }}</span>
        </template>
        <template #lastModificationTime="{ record }">
          <span>{{ $filters.utcToCurrentTime(record.lastModificationTime) }}</span>
        </template>
        <template #commissionRate="{ record }">
          <span>{{ record.commissionRate }}%</span>
        </template>
        <template #amount="{ record }">
          <span>{{ record.currencyCode }} {{ record.currencySymbol }} {{ roundNumber(record.amount) }}</span>
        </template>
        <template #commissionBusinessType="{ record }">
          <span>{{ $t($enumLangkey('commissionBusinessType', record.commissionBusinessType)) }}</span>
        </template>
        <template #computeModeType="{ record }">
          <span>{{ $t($enumLangkey('computeModeType', record.computeModeType)) }}</span>
        </template>
      </a-table>
    </template>
    <template #contentFooter
      ><CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager
    ></template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { Card, Row, Col, Button, Table } from "ant-design-vue";
import Content from "@/views/components/Content";
import CPager from "@/views/components/CPager";
import { useI18n } from "vue-i18n/index";
import { getPartnerBalance, getListPaged } from '@/api/modules/commission';
import { roundNumber } from '@/utils/general';

export default defineComponent({
  name: "commission",
  components: {
    ACard: Card,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ATable: Table,
    Content,
    CPager,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      balanceData: {
        amount: 0,
        awaitConfirmAmount: 0,
        confirmAmount: 0,
        canceledAmount: 0,
      },
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        title: () => vueI18n.t("commission.time_of_occurrence"),
        width: 150,
        fixed: "right",
        slots: {
          customRender: "creationTime",
        },
        align: "center"
      },
      {
        title: () => vueI18n.t("commission.status"),
        width: 100,
        slots: {
          customRender: "commissionRecordStatus",
        },
        align: "center"
      },
      {
        title: () => vueI18n.t("commission.update_time"),
        width: 150,
        slots: {
          customRender: "lastModificationTime",
        },
        align: "center"
      },
      {
        title: () => vueI18n.t("commission.commission_rate"),
        width: 80,
        slots: {
          customRender: "commissionRate",
        },
        align: "center"
      },
      {
        title: () => vueI18n.t("account.amount"),
        width: 120,
        slots: {
          customRender: "amount",
        },
        align: "center"
      },
      {
        title: () => vueI18n.t("commission.business_type"),
        width: 120,
        slots: {
          customRender: "commissionBusinessType",
        },
        align: "center"
      },
      {
        title: () => vueI18n.t("commission.calculation_method"),
        width: 120,
        slots: {
          customRender: "computeModeType",
        },
        align: "center"
      },
      {
        dataIndex: "relatedBusiness",
        title: () => vueI18n.t("commission.related_business"),
        width: 120,
        align: "center"
      },
    ];

    const getPageData = () => {
      state.tableData.loading = true
      const data = Object.assign({}, state.tableData.pageData)
      getListPaged(data).then((res) => {
        let { result } = res
        if (result) {
          let { totalCount = 0, items = [] } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items.map((item, index) => {
            item.key = String(index);
            return item;
          });
        }
      }).finally(() => {
        state.tableData.loading = false
      })
    }

    const handleInitSearch = () => {
      getBalanceData();
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    }

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    }

    const getBalanceData = () => {
      getPartnerBalance().then((res) => {
        let { result } = res
        if (result) {
          for (const key in state.balanceData) {
            if (Object.hasOwnProperty.call(result, key)) {
              state.balanceData[key] = `${result['currencyCode']} ${result['currencySymbol']}${roundNumber(result[key])}`
            }
          }
        }
      })
    }

    onMounted(handleInitSearch);

    return {
      ...toRefs(state),
      columns,
      handlePage,
      handleInitSearch,
      roundNumber,
    };
  },
});
</script>
