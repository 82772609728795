export default {
  settlement: {
    confirmed_amount: "已确认金额",
    settled_amount: "已结算金额",
    unsettled_amount: "未结算金额",
    settlement_account_number: "结算账号",
    settlement_record: "结算记录",
    date: "日期",
    serial_number: "流水号",
    settlement_channel: "结算渠道",
    account_name: "账号名称",
    bank_of_deposit: "开户银行",
    collection_account_number: "收款账号",
    payee: "收款方",
  }
}