<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.links") }}
      <a-button
        type="ghost"
        shape="circle"
        size="small"
        @click="handleInitSearch"
        :loading="tableData.loading"
      >
        <template #icon>
          <RedoOutlined />
        </template>
      </a-button>
    </template>
    <template #contentBody="wrap">
      <a-row
        :gutter="[16, 8]"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <a-col :xl="12" :lg="14" :md="18" :sm="20" :xs="24">
          <a-card v-if="showLinks">
            <div>{{ $t("links.recommended_link_use_cases") }}:</div>
            <a-row :gutter="[16, 8]">
              <a-col :span="20">
                <a-space>
                  <span>{{ homeLink }}</span>
                  <a-button
                    class="copyBtn"
                    type="link"
                    @click="handleCopy(homeLink)"
                    >{{ $t("common.copy") }}</a-button
                  >
                </a-space>
              </a-col>
              <a-col :span="4">{{ $t("menu.home") }}</a-col>
            </a-row>
            <a-row :gutter="[16, 8]">
              <a-col :span="20">
                <a-space>
                  <span>{{ registerLink }}</span>
                  <a-button
                    class="copyBtn"
                    type="link"
                    @click="handleCopy(registerLink)"
                    >{{ $t("common.copy") }}</a-button
                  >
                </a-space>
              </a-col>
              <a-col :span="4">{{ $t("links.register_page") }}</a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleCreate">{{
            $t("links.create_label")
          }}</a-button>
        </a-col>
      </a-row>
      <div class="mt-3 mb-2">{{ $t("links.label_management") }}</div>
      <a-table
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 230 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #name="{ record }">
          <div class="editable-cell">
            <div
              v-if="editableData[record.key]"
              class="editable-cell-input-wrapper"
            >
              <a-input
                v-model:value="editableData[record.key].name"
                @pressEnter="handleUpdate(record.key, record)"
                allowClear
              />
              <check-outlined
                class="editable-cell-icon-check"
                @click="handleUpdate(record.key, record)"
              />
            </div>
            <div v-else class="editable-cell-text-wrapper">
              {{ record.name || " " }}
              <edit-outlined
                class="editable-cell-icon"
                @click="handleShowEdit(record.key, record)"
              />
            </div>
          </div>
        </template>
        <template #creationTime="{ record }">
          <span>{{ $filters.utcToCurrentTime(record.creationTime) }}</span>
        </template>
        <template #awaitConfirmAmount="{ record }">
          {{ record.currencySymbol
          }}{{ roundNumber(record.awaitConfirmAmount) }}
        </template>
        <template #confirmAmount="{ record }">
          {{ record.currencySymbol }}{{ roundNumber(record.confirmAmount) }}
        </template>
        <template #canceledAmount="{ record }">
          {{ record.currencySymbol }}{{ roundNumber(record.canceledAmount) }}
        </template>
        <template #amount="{ record }">
          {{ record.currencySymbol }}{{ roundNumber(record.amount) }}
        </template>
        <template #link="{ record }">
          <a-space v-if="showLinks">
            <a-button
              type="link"
              size="small"
              @click="handleOpenCopyModal(record.value)"
            >
              <template #icon>
                <CopyOutlined />
              </template>
            </a-button>
          </a-space>
        </template>
      </a-table>
      <CreateModal ref="createModalRef" @refresh="handleInitSearch" />
      <CopyModal ref="copyModalRef" :domain-host="domainHost" :partner-no="partnerNo" />
    </template>
    <template #contentFooter
      ><CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager
    ></template>
  </Content>
</template>

<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import {
  Card,
  Row,
  Col,
  Button,
  Space,
  Table,
  Modal,
  Form,
  Input,
  message,
} from "ant-design-vue";
import Content from "@/views/components/Content";
import CPager from "@/views/components/CPager";
import CreateModal from "./components/CreateModal.vue";
import CopyModal from "./components/CopyModal.vue";
import { useI18n } from "vue-i18n/index";
import useClipboard from "vue-clipboard3";
import {
  getReferralLinkInfo,
  getListPaged,
  updatePartnerLabelName,
} from "@/api/modules/links";
import { roundNumber } from "@/utils/general";

export default defineComponent({
  name: "links",
  components: {
    ACard: Card,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ASpace: Space,
    ATable: Table,
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    Content,
    CPager,
    CreateModal,
    CopyModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { toClipboard } = useClipboard();

    const createModalRef = ref(null);
    const copyModalRef = ref(null);

    const state = reactive({
      showLinks: false,
      homeLink: "",
      registerLink: "",
      tagLink: "",
      domainHost: "",
      partnerNo: "",
      tableData: {
        loading: true,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const editableData = reactive({});

    const columns = [
      {
        title: () => vueI18n.t("links.label_name"),
        width: 150,
        fixed: "right",
        slots: {
          customRender: "name",
        },
        align: "center",
      },
      {
        dataIndex: "value",
        title: () => vueI18n.t("links.tag_value"),
        width: 100,
        align: "center",
      },
      {
        title: () => vueI18n.t("links.creation_time"),
        width: 100,
        slots: {
          customRender: "creationTime",
        },
        align: "center",
      },
      {
        dataIndex: "visitsCount",
        title: () => vueI18n.t("links.visits"),
        width: 80,
        align: "center",
      },
      {
        dataIndex: "shopRegisterCount",
        title: () => vueI18n.t("links.number_of_customer_registrations"),
        width: 80,
        align: "center",
      },
      {
        title: () => vueI18n.t("links.commission_to_be_confirmed"),
        width: 100,
        slots: {
          customRender: "awaitConfirmAmount",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("links.commission_confirmed"),
        width: 100,
        slots: {
          customRender: "confirmAmount",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("links.commission_cancelled"),
        width: 100,
        slots: {
          customRender: "canceledAmount",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("links.total"),
        width: 100,
        slots: {
          customRender: "amount",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("links.link"),
        width: 80,
        slots: {
          customRender: "link",
        },
        align: "center",
      },
    ];

    const handleCopy = async (text) => {
      try {
        await toClipboard(text);
        message.success(vueI18n.t("common.copy") + vueI18n.t("common.succeed"));
      } catch (error) {}
    };

    const handleOpenCopyModal = (tag) => {
      copyModalRef.value.init(tag);
    };

    const handleCreate = () => {
      createModalRef.value.init();
    };

    const handleShowEdit = (key, record) => {
      editableData[key] = Object.assign({}, record);
    };

    const handleUpdate = (key, record) => {
      const data = {
        partnerLabelId: editableData[key].id,
        name: editableData[key].name,
      };
      if (!data.name) {
        message.error(
          vueI18n.t("common.p0_is_required", [vueI18n.t("links.label_name")])
        );
        return;
      }
      updatePartnerLabelName(data).then(() => {
        Object.assign(record, editableData[key]);
        delete editableData[key];
      });
    };

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign({}, state.tableData.pageData);
      getListPaged(data)
        .then(({ result }) => {
          if (result) {
            let { totalCount = 0, items = [] } = result;
            state.tableData.pageData.totalCount = parseInt(totalCount);
            state.tableData.tableList = items.map((item, index) => {
              item.key = String(index);
              return item;
            });
          }
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const init = async () => {
      try {
        let { result } = await getReferralLinkInfo();
        if (result) {
          let { domainHost = "", partnerNo = "" } = result;
          if (domainHost && partnerNo) {
            state.homeLink = `${domainHost}/home?pt=${partnerNo}`;
            state.registerLink = `${domainHost}/register?pt=${partnerNo}`;
            state.tagLink = `${domainHost}?pt=${partnerNo}&tag=`;
            state.showLinks = true;
            state.domainHost = domainHost;
            state.partnerNo = partnerNo;
          }
        }
        handleInitSearch();
      } catch (error) {}
    };

    onMounted(init);

    return {
      ...toRefs(state),
      editableData,
      columns,
      createModalRef,
      copyModalRef,
      handlePage,
      handleInitSearch,
      handleCopy,
      handleCreate,
      handleShowEdit,
      handleUpdate,
      roundNumber,
      handleOpenCopyModal,
    };
  },
});
</script>

<style lang="less" scoped>
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>