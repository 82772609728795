export default {
  "menu": {
    "home": "Home Page",
    "dashboard": "Dashborad",
    "account_info": "Account info",
    "account_restpwd": "Reset password",
    "account_center": "Account center",
    "settings": "Settings",
    "about": "About",
    "error": "Error page",
    "error_403": "403",
    "error_404": "404",
    "error_500": "500",
    "links": "Promotion Links Management",
    "customer": "Client Management",
    "commission": "Commission Management",
    "settlement": "Settlement Management"
  }
}