export default {
  "customer": {
    "customer": "Client",
    "country_of_registration": "Country Of Registration",
    "source_label": "Sources Tag",
    "available_status": "Availability Status",
    "commission_to_be_confirmed": "Commission To Be Confirmed",
    "normal_commission_distribution": "Normal Commission Sharing",
    "termination_of_commission_distribution": "Stop Commission Sharing"
  }
}