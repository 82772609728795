import moment from "moment-timezone";
import { getTimezone } from "../hooks/login/index";
import { roundNumber } from '@/utils/general';

const utcToCurrentTime = (date, format = "yyyy-MM-DD HH:mm") => {
  const userTimezone = getTimezone();
  if (!date) {
    return "-";
  }
  return moment.tz(new Date(date), userTimezone).format(format);
};

// 金额显示为保留两位小数
const amountToFixed2 = (amount, digits = 2) => {
  amount = parseFloat(amount)
  if (Number.isNaN(amount)) {
    amount = 0
  }
  return roundNumber(amount, digits)
}

// 格式化 币种与金额的显示
const formatCurrencyAmount = (symbol, amount, isShowPositiveSign = true, digits = 2) => {
  if (!symbol) {
    symbol = "";
  }
  if (!amount || amount === 0) {
    return symbol + amountToFixed2(amount, digits);
  } else if (amount > 0) {
    let positiveSign = isShowPositiveSign ? "+" : "";
    return positiveSign + symbol + amountToFixed2(amount, digits);
  } else {
    return "-" + symbol + amountToFixed2(Math.abs(amount), digits);
  }
};

const countProgressFormat = (num1, num2) => {
  num1 = (parseFloat(num1) || 0)
  num2 = (parseFloat(num2) || 0)
  if (num1 == 0) {
    return num2
  }
  return num1 + ' / ' + num2
}

export default {
  utcToCurrentTime,
  amountToFixed2,
  formatCurrencyAmount,
  countProgressFormat,
};
