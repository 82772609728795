export default {
  enum: {
    empty: "",

    // 佣金费用记录状态
    commission_record_status_1: "待确认",
    commission_record_status_2: "已确认",
    commission_record_status_3: "已取消",

    // 合作伙伴业务类型
    commission_business_type_1: "仓储服务",
    commission_business_type_2: "运输服务",

    // 计算方式
    compute_mode_type_1: "按单统计",
    compute_mode_type_2: "按月统计",

    // 相关业务类型
    related_business_type_1: "UPSDSASD",
    related_business_type_2: "按月汇总计算",

    // 结算渠道
    settlement_channel_type_1: "银行电汇",
  },
};
