const baseResJson = {
    code:0,
    success: true,
    result: undefined,
    message: 'ok',
    timestamp: 0,
  }


  export  const getMockToken = () => {
    const tempUrl = URL.createObjectURL(new Blob());
    const token = tempUrl.toString();
    URL.revokeObjectURL(tempUrl);
    return token.substr(token.lastIndexOf("/") + 1);
  }

  export const generatorResponse = (data = null, message="ok", success = true)=>{
    const response = Object.assign({}, baseResJson, {//作用是把第二和第三个参数合并成第一个参数
        code:0,
        success,
        result: data,
        message,
        timestamp: new Date().getTime()
      })
      return response
  }

  
export const getRequestBody = request => {
    const {body} = request
    return JSON.parse(body)
  }