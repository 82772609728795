export default {
  "account": {
    "essential_information": "Basic Information",
    "user_name": "User Name",
    "timezone": "Timezone",
    "mobile_phone": "Mobile Phone",
    "last_login_time": "Latest Login Time",
    "last_login_ip": "Last Login IP",
    "secure_mailbox": "Security Mailbox",
    "account_security": "Account Security",
    "change_password": "Change Password",
    "original_password": "Original Password",
    "new_password": "New Password",
    "enter_the_new_password_again": "Enter Again",
    "please_input": "Enter",
    "the_passwords_entered_twice_do_not_match": "New Password Inconsistent",
    "password_modified_successfully": "Password Changed Successfully",
    "amend": "Amend",
    "add": "Add",
    "company_list": "COmpany List",
    "account_list": "Account List",
    "all": "All",
    "check_pending": "Pending for approval",
    "in_the_activity": "In Active",
    "audit_failed": "Approval Failed",
    "pass_the_audit": "Approved",
    "company_name": "Company Name",
    "registr_id": "Registration Number",
    "the_head_of_a_company": "Company Principal",
    "country": "Country",
    "registration_date": "Registration Time",
    "approval_time": "Approved Time",
    "last_login_int": "Last Login Time",
    "account_status": "Account Status",
    "audit_status": "Approval Status",
    "business_manager": "Business Principal",
    "service": "Customer Service",
    "operation": "Operation",
    "operator": "Operator",
    "details": "Details",
    "company_details": "Company Details",
    "audit": "Approve",
    "audit_result": "Approval Result",
    "freeze": "Freeze",
    "unfreeze": "Unfreeze",
    "account_audit": "Account Approval",
    "pass": "Approve",
    "reject": "Reject",
    "business_license": "Business License",
    "account_principal": "Account Owner",
    "phone": "Phone",
    "audit_result_placeholder": "Reason for approval failed or remarks for approval.",
    "account_balance": "Account Balance",
    "overdraft_limit": "Overdraft Limit",
    "rxpire_date": "Due Date",
    "overdraft_status": "Overdraft Status",
    "serial_number": "Serial Number",
    "log_in_time": "Login Time",
    "time": "Time",
    "quota_adjustment_record": "Overdraft Adjustment Record",
    "amount": "Amount",
    "adjustment_type": "Adjustment Type",
    "login_id": "Login Account",
    "account_name": "Account Name",
    "locked": "Locked",
    "locking": "Lock",
    "unlock": "Unlock",
    "login_account": "Login Account",
    "you_are_performing_a_delete_operation": "You're excuting delete operation.",
    "update_timezone": "Modify Timezone",
    "choose_timezone": "Select Timezone"
  }
}