import Mock from "mockjs";
import { generatorResponse, getMockToken, getRequestBody } from "./tools";
import { useI18n } from "vue-i18n/index";

const useMock = true;
if (useMock) {

  //#region login logout getMenus
  const login = (request) => {
    const { username, password } = getRequestBody(request);
    if (username !== "admin" || password.length < 6) {
      const i18n = useI18n({ useScope: "global" });
      return generatorResponse(
        null,
        i18n.t("common.incorrect-username-or-password"),
        false
      );
    } else {
      const userInfo = {
        id: Math.random().toString(36).slice(2),
        username: username,
        token: getMockToken(),
        //http://www.jj20.com/tx/QQ/276798.html
        avatar: "http://img.jj20.com/up/allimg/tx28/112320206782.jpg",
      };
      return generatorResponse(userInfo);
    }
  };

  const logout = (request) => {
    return generatorResponse(null);
  };

  //Mock.mock(/\/api\/login/, "post", login);
  Mock.mock(/\/api\/logout/, "post", logout);
  //#endregion

  
  Mock.setup({
    timeout: 0,
  });
}
