import client from "@/api/client";

const Api = {
  //更新密码
  updatePwd: "/api/SupplierService/Account/UpdatePwd",
  // 获取所有时区
  getTimeZoneList: "/api/BasicDataService/CustomTimeZone/GetList",
  // 更改账户时区
  updateTimezone: "/api/URCService/User/UpdateTimezone",
}

export const updatePwd = (data) => {
  return client.request({
    url: Api.updatePwd,
    data,
    method: "POST",
  });
};

export const getTimeZoneList = (data) => {
  return client.request({
    url: Api.getTimeZoneList,
    data,
    method: "POST",
  });
};

export const updateTimezone = (data) => {
  return client.request({
    url: Api.updateTimezone,
    data,
    method: "PUT",
  });
};