import client from "@/api/client";

const Api = {
  getPartner: "/api/SupplierService/Partner/Get",
  getListPaged: "/api/SupplierService/PartnerSettlementRecord/GetListPaged",
}

export const getPartner = () => {
  return client.request({
    url: Api.getPartner,
    method: "GET",
  });
};

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: "POST",
  });
};