export default {
  menu: {
    home: "首页",
    dashboard: "工作空间",

    account_info: "账户信息",
    account_restpwd: "重置密码",
    account_center: "个人中心",

    settings: "设置",
    about: "关于",

    error: "错误页面",
    error_403: "403",
    error_404: "404",
    error_500: "500",

    links: "推荐链接管理",
    customer: "客户管理",
    commission: "佣金管理",
    settlement: "结算管理",
  },
};
