<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.settlement') }}
      <a-button
        type="ghost"
        shape="circle"
        size="small"
        @click="handleInitSearch"
        :loading="tableData.loading"
      >
        <template #icon>
          <RedoOutlined />
        </template>
      </a-button>
    </template>
    <template #contentBody="wrap">
      <a-space :size="100">
        <div>
          <p style="font-weight: bold;">{{ $t('settlement.confirmed_amount') }}</p>
          <p>{{ balanceData.confirmAmount }}</p>
        </div>
        <div>
          <p style="font-weight: bold;">{{ $t('settlement.settled_amount') }}</p>
          <p class="text-success">{{balanceData.settlementAmount}}</p>
        </div>
        <div>
          <p style="font-weight: bold;">{{ $t('settlement.unsettled_amount') }}</p>
          <p style="color: #AAAAAA">{{balanceData.noSettlementAmount}}</p>
        </div>
      </a-space>
      <div class="mt-3 mb-2" style="font-weight: bold;">{{ $t('settlement.settlement_account_number') }}</div>
      <a-card style="width: 500px;">
        <a-row>
          <a-col style="width: 100px; font-weight: bold;">{{ $t('settlement.settlement_channel') }}</a-col>
          <a-col>{{ $t($enumLangkey('settlementChannelType', partnerSettlementChannel.settlementChannelType)) }}</a-col>
        </a-row>
        <a-row>
          <a-col style="width: 100px;">{{ $t('settlement.account_name') }}:</a-col>
          <a-col>{{ partnerSettlementChannel.accountName }}</a-col>
        </a-row>
        <a-row>
          <a-col style="width: 100px;">{{ $t('settlement.bank_of_deposit') }}:</a-col>
          <a-col>{{ partnerSettlementChannel.bankName }}</a-col>
        </a-row>
        <a-row>
          <a-col style="width: 100px;">{{ $t('settlement.collection_account_number') }}:</a-col>
          <a-col>{{ partnerSettlementChannel.collectionAccountNumber }}</a-col>
        </a-row>
      </a-card>
      <div class="mt-3 mb-2" >{{ $t('settlement.settlement_record') }}</div>
      <a-table
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 350 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #creationTime="{ record }">
          <span>{{ $filters.utcToCurrentTime(record.creationTime) }}</span>
        </template>
        <template #partnerName="{ record }">
          <span v-if="record.partnerName">{{ record.partnerName }}</span>
          <span v-else>-</span>
        </template>
        <template #amount="{ record }">
          <span v-if="record.amount">{{ record.currencyCode }} {{ record.currencySymbol }}{{ roundNumber(record.amount) }}</span>
          <span v-else>-</span>
        </template>
        <template #settlementChannelType="{ record }">
          <span>{{ $t($enumLangkey('settlementChannelType', record.settlementChannelType)) }}</span>
        </template>
        <template #settlement="{ record }">
          <a-space v-if="record.collectionAccountNumber" :size="50">
            <span>{{ record.accountName }}</span>
            <span>{{ record.bankName }}({{ record.collectionAccountNumber }})</span>
          </a-space>
          <span v-else>-</span>
        </template>
      </a-table>
    </template>
    <template #contentFooter
      ><CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager
    ></template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { Button, Card, Table, Space, Row, Col } from 'ant-design-vue';
import Content from '@/views/components/Content';
import CPager from '@/views/components/CPager';
import { useI18n } from "vue-i18n/index";
import { getPartnerBalance } from '@/api/modules/commission';
import { getPartner, getListPaged } from '@/api/modules/settlement';
import { roundNumber } from '@/utils/general';

export default defineComponent({
  name: "settlement",
  components: {
    AButton: Button,
    ACard: Card,
    ATable: Table,
    ASpace: Space,
    ACard: Card,
    ATable: Table,
    ARow: Row,
    ACol: Col,
    Content,
    CPager,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      balanceData: {
        confirmAmount: 0,
        settlementAmount: 0,
        noSettlementAmount: 0,
      },
      partnerSettlementChannel: {
        settlementChannelType: null,
        accountName: null,
        bankName: null,
        collectionAccountNumber: null,
      },
      tableData: {
        loading: true,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    })

    const columns = [
      {
        title: () => vueI18n.t("settlement.date"),
        width: 150,
        slots: {
          customRender: "creationTime",
        },
        align: "center"
      },
      {
        dataIndex: "settlementNo",
        title: () => vueI18n.t("settlement.serial_number"),
        width: 200,
        align: "center"
      },
      {
        title: () => vueI18n.t("account.amount"),
        width: 150,
        slots: {
          customRender: "amount",
        },
        align: "center"
      },
      {
        title: () => vueI18n.t("settlement.settlement_channel"),
        width: 150,
        slots: {
          customRender: "settlementChannelType",
        },
        align: "center"
      },
      {
        title: () => vueI18n.t("settlement.payee"),
        width: 350,
        slots: {
          customRender: "settlement",
        },
        align: "center"
      },
    ];

    const getPageData = () => {
      state.tableData.loading = true
      const data = Object.assign({}, state.tableData.pageData)
      getListPaged(data).then(({ result }) => {
        if (result) {
          let { totalCount = 0, items = [] } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        }
      }).finally(() => {
        state.tableData.loading = false
      })
    }

    const handleInitSearch = () => {
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getPageData();
    }

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    }

    const init = async () => {
      try {
        let { result: partnerBalance } = await getPartnerBalance()
        if (partnerBalance) {
          for (const key in state.balanceData) {
            if (Object.hasOwnProperty.call(partnerBalance, key)) {
              state.balanceData[key] = `${partnerBalance['currencyCode']} ${partnerBalance['currencySymbol']}${roundNumber(partnerBalance[key])}`
            }
          }
        }
        
        let { result: partner } = await getPartner()
        if (partner) {
          let { partnerSettlementChannel } = partner
          for (const key in state.partnerSettlementChannel) {
            if (Object.hasOwnProperty.call(partnerSettlementChannel, key)) {
              state.partnerSettlementChannel[key] = partnerSettlementChannel[key]
            }
          }
        }

        handleInitSearch()
      } catch (error) {
        
      }
    }

    onMounted(init)

    return {
      ...toRefs(state),
      columns,
      handleInitSearch,
      handlePage,
      roundNumber,
    }
  },
})
</script>
