export default {
  "settlement": {
    "confirmed_amount": "Confirmed Amount",
    "settled_amount": "Settled Amount",
    "unsettled_amount": "Unsettled Amount",
    "settlement_account_number": "Account for settlement",
    "settlement_record": "Settlement Record",
    "date": "Date",
    "serial_number": "Serial Number",
    "settlement_channel": "Settlement Channel",
    "account_name": "Account Name",
    "bank_of_deposit": "Bank Of Deposit",
    "collection_account_number": "Payee Account",
    "payee": "Payee"
  }
}