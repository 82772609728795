import client from "@/api/client";

const Api = {
  // 获取推荐链接基本信息
  getReferralLinkInfo: "/api/SupplierService/PartnerLabel/GetReferralLinkInfo",
  // 获取合作伙伴标签分页列表
  getListPaged: "/api/SupplierService/PartnerLabel/GetListPaged",
  // 创建合作伙伴标签
  createPartnerLabel: "/api/SupplierService/PartnerLabel/Create",
  // 修改合作伙伴标签名称
  updatePartnerLabelName: "/api/SupplierService/PartnerLabel/UpdatePartnerLabelName",
}

export const getReferralLinkInfo = () => {
  return client.request({
    url: Api.getReferralLinkInfo,
    method: "GET",
  });
};

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: "POST",
  });
};

export const createPartnerLabel = (data) => {
  return client.request({
    url: Api.createPartnerLabel,
    data,
    method: "POST",
  });
};

export const updatePartnerLabelName = (data) => {
  return client.request({
    url: Api.updatePartnerLabelName,
    data,
    method: "POST",
  });
};